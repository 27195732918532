import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from "@mui/joy";

const Header = ({children}) => (
    <Typography
        level="h1"
        component="div"
        color="neutral"
        display="flex"
        flexDirection="row"
        justifyContent="space-between">
        {children}
    </Typography>
);

Header.propTypes = {
    children: PropTypes.node,
};

export default Header;
