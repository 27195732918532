import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Box} from "@mui/joy";

import CommonUtils from "utils/CommonUtils";
import {Profile} from "domain";

const ProfileLabel = ({ profile }) => (
    <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <Avatar
            variant="soft"
            size="sm"
            src={profile.picUrl}
        >
            {CommonUtils.getInitials(profile.name)}
        </Avatar>
        <Box sx={{
            display: {xs: 'none', sm: 'inline-block'},
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }}>
            {profile.name}
        </Box>
    </Box>
);

ProfileLabel.propTypes = {
    profile: PropTypes.instanceOf(Profile),
};

export default ProfileLabel;
