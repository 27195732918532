import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardOverflow, Box, Button, Typography, Chip, Stack } from '@mui/joy';
import GoIcon from '@mui/icons-material/PlayCircle';

import { Classroom, Profile } from 'domain';
import ProfileLabel from "components/core/ProfileLabel";
import LanguageLabel from "components/core/LanguageLabel";

const ClassroomListItem = ({ classroom, perspective, onClick }) => {
    return (
        <Card
            variant="outlined"
            color="primary"
            orientation="vertical"
            onClick={() => {
                onClick(classroom)
            }}
            sx={{
                minWidth: {xs: '100%', sm: 300},
                maxWidth: {xs: '100%', sm: 600},
                cursor: 'pointer',
                '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
            }}
        >
            <CardOverflow variant="soft">
                <Box py={1}>
                    <Typography level="title-lg" color="neutral">{classroom.course.name}</Typography>
                </Box>
            </CardOverflow>
            <CardContent>
                <Typography color="neutral" component="div">
                    <Stack gap={2} direction={{ xs: 'row', sm: 'column'}}>
                        <ProfileLabel profile={perspective === Profile.TYPE_STUDENT ? classroom?.tutor?.get('profile') : classroom?.student?.get('profile')} />
                        <LanguageLabel language={classroom.course.language} />
                        <Box align="right">
                            <Chip color="primary">{Classroom.STATUS_LABELS[classroom.status]}</Chip>
                        </Box>
                    </Stack>
                </Typography>
            </CardContent>
            <CardOverflow>
                <Button variant="solid" color="primary" endDecorator={<GoIcon />} size="lg">
                    Launch
                </Button>
            </CardOverflow>
        </Card>
    );
};

ClassroomListItem.propTypes = {
    classroom: PropTypes.instanceOf(Classroom).isRequired,
    perspective: PropTypes.oneOf(Profile.TYPES).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ClassroomListItem;
