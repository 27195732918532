import {EnrollmentRequest} from 'domain';
const Parse = global.Parse;

const ENROLLMENT_REQUEST_FIELDS = ['requester', 'course', 'message', 'status'];

const get = (id) => ({
    type: 'GET_ENROLLMENT_REQUEST',
    meta: { id },
    payload: new Parse.Query(EnrollmentRequest)
        .select(ENROLLMENT_REQUEST_FIELDS)
        .get(id),
});

const set = (enrollmentRequest) => {
    return {
        type: 'SET_ENROLLMENT_REQUEST',
        meta: { enrollmentRequest },
        payload: Promise.resolve(enrollmentRequest),
    };
};

const save = (enrollmentRequest) => {
    return {
        type: 'SAVE_ENROLLMENT_REQUEST',
        meta: { enrollmentRequest },
        payload: enrollmentRequest.save(),
    };
};

const listByCourse = (course, status = [EnrollmentRequest.STATUS_PENDING]) => ({
    type: 'LIST_ENROLLMENT_REQUEST_BY_COURSE',
    meta: { course },
    payload: new Parse.Query(EnrollmentRequest)
        .equalTo('course', course)
        .containedIn('status', status)
        .select(ENROLLMENT_REQUEST_FIELDS)
        .include(['requester'])
        .find(),
});

const listByProfile = (profile, status = [EnrollmentRequest.STATUS_PENDING]) => ({
    type: 'LIST_ENROLLMENT_REQUEST_BY_PROFILE',
    meta: { profile },
    payload: new Parse.Query(EnrollmentRequest)
        .equalTo('requester', profile)
        .containedIn('status', status)
        .select(ENROLLMENT_REQUEST_FIELDS)
        .include(['course'])
        .find(),
});

const clear = () => ({
    type: 'CLEAR_ENROLLMENT_REQUEST',
    payload: Promise.resolve(null)
});

export default {
    get,
    set,
    save,
    listByCourse,
    listByProfile,
    clear,
};
