import React from 'react';
import {useLocation, Link as RouterLink, useParams} from 'react-router-dom';
import { Breadcrumbs, Typography, Box, Link } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import {useSelector} from "react-redux";

const Breadcrumb = () => {
    const location = useLocation();
    const { profileId, courseId, messageId, sectionId, materialId, classroomId } = useParams();
    const pathnames = location.pathname.split('/').filter(x => x);

    const me = useSelector(({ profile }) => profile.me);
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const profile = useSelector(({ profile }) => profile.current);
    const message = useSelector(({ message }) => message.current);
    const course = useSelector(({ course }) => course.current);
    const section = useSelector(({ section }) => section.current);
    const material = useSelector(({ material }) => material.current);
    const classroom = useSelector(({ classroom }) => classroom.current);

    if(!me?.id || !isAuthorized) {
        return null;
    }

    const isInCourse = (courseId && course != null && course.id === courseId);
    const isInClassroom = (classroomId && classroom != null && classroom.id === classroomId);

    const crumbs = [
        {id: 'messages', urlPart: 'message', label: "Messages", enabled: pathnames.includes('message')},
        {id: 'message', urlPart: message?.id, label: message?.subject, enabled: (messageId && message != null && message.id === messageId)},
        {id: 'profile', urlPart: profile?.id, label:  profile?.name, enabled: (profileId && profile != null && profile.id === profileId)},
        {id: 'subscription', urlPart: 'subscription', label:  "Manage Subscription", enabled: pathnames.includes('subscription')},
        {id: 'classrooms', urlPart: 'classroom', label: "Classrooms", enabled: location.pathname === '/' || pathnames.includes('classroom')},
        {id: 'courses', urlPart: 'course', label: "Courses", enabled: pathnames.includes('course')},
        {id: 'course', urlPart: course?.id, label:  course?.name, enabled: isInCourse && !isInClassroom},
        {id: 'classroom', urlPart: `${course?.id}/${classroom?.id}`, label:  classroom?.course?.name, enabled: isInClassroom},
        {id: 'section', urlPart: `section/${section?.id}`, label:  section?.name, enabled: (sectionId && section != null && section.id === sectionId)},
        {id: 'material', urlPart: material?.id, label:  material?.name, enabled: (materialId && material != null && material.id === materialId)},
    ].filter(bc => bc.enabled)

    const crumbParts = crumbs.map(crumb => crumb.urlPart)

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }} px={2}>
            <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon fontSize="sm" />}
                sx={{ pl: 0 }}
            >
                <HomeRoundedIcon />
                {crumbs.map((crumb, index) => {
                    const last = index === crumbs.length - 1;
                    const to = `/${crumbParts.slice(0, index + 1).join('/')}`;

                    return last ? (
                        <Typography
                            key={crumb.id}
                            color="primary"
                            fontWeight={500}
                            fontSize={12}
                            sx={{
                                maxWidth: `${50/crumbs.length}vw`,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {crumb.label}
                        </Typography>
                    ) : (
                        <Link
                            key={crumb.id}
                            component={RouterLink}
                            to={to}
                            underline="hover"
                            color="neutral"
                            fontSize={12}
                            fontWeight={500}
                            sx={{
                                maxWidth: `${50/crumbs.length}vw`,
                                display: 'inline-block',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {crumb.label}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );
};

export default Breadcrumb;
