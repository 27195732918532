import React from 'react';
import { useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography, IconButton, Menu, MenuItem, MenuButton, Dropdown } from '@mui/joy';
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import MenuIcon from "@mui/icons-material/Menu";

import logo from '../../img/ofall-logo.png';

const TopBar = () => {
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);

    if (isAuthorized) {
        return null;
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.surface',
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                py: 1,
                px: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >
                <img src={logo} alt="Logo" height={36} />
                <Typography
                    color="primary"
                    level="h3"
                    noWrap
                    variant="plain"
                >
                    OFALL
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    alignItems: 'center',
                    display: { xs: 'none', md: 'flex' },
                }}
            >
                <Button component="a" href="https://ofall.org" target="_blank" variant="plain">
                    <SupportRoundedIcon/>
                    Support
                </Button>
                <Button component={RouterLink} to="/" color="primary" variant="plain">
                    Course Catalog
                </Button>
                <Button component={RouterLink} to="/classroom" color="primary" variant="plain">
                    Sign In
                </Button>
                <Button component={RouterLink} to="/signup" color="primary" variant="soft">
                    Sign Up For Free
                </Button>
            </Box>

            <Dropdown>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                    sx={{
                        display: { xs: 'inherit', md: 'none' },
                    }}
                >
                    <MenuIcon />
                </MenuButton>
                <Menu>
                    <MenuItem component="a" color="neutral" href="https://ofall.org" target="_blank">
                        Support
                    </MenuItem>
                    <MenuItem component={RouterLink} color="neutral" to="/">
                        Course Catalog
                    </MenuItem>
                    <MenuItem component={RouterLink} color="neutral" to="/classroom">
                        Sign In
                    </MenuItem>
                    <MenuItem component={RouterLink} color="neutral" to="/signup">
                        Sign Up For Free
                    </MenuItem>
                </Menu>
            </Dropdown>
        </Box>
    );
}

TopBar.propTypes = {};

export default TopBar;
