import React from 'react';
import {Outlet} from "react-router-dom";
import { Box, Stack } from '@mui/joy';

import Loader from 'components/core/Loader';
import Notices from 'components/core/Notices';
import Sidebar from 'components/core/Sidebar';
import TopBar from "components/core/TopBar";
import Breadcrumb from "components/core/Breadcrumb";

const Layout = () => (
    <Stack>
        <Loader />
        <TopBar />
        <Box display="flex" flexDirection="row" gap={0}>
            <Sidebar />
            <Stack flexGrow={1} gap={0}>
                <Breadcrumb />
                <Box p={2} component="main" flexGrow={1}>
                    <Outlet />
                </Box>
            </Stack>
        </Box>
        <Notices key="notices" />
    </Stack>
);

export default Layout;
