import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/joy';
import {ClassroomMessage} from "domain";
import DOMPurify from "dompurify";
import {marked} from "marked";

const ChatBoxMessage = ({ message, sender }) => {
    const renderer = new marked.Renderer();

    renderer.paragraph = (text) => `<div>${text}</div>`;
    renderer.code = (text) => (
        `<pre style="overflow-x: auto; background-color: var(--joy-palette-background-body); padding: 4px"><code>${text}</code></pre>`
    );

    const output = DOMPurify.sanitize(marked.parse(message.text ?? '', { gfm: true, breaks: true, renderer}));

    const messageStyle = (theme) => ({
        maxWidth: '70%',
        padding: theme.spacing(1, 2),
        borderRadius: 'var(--joy-radius-xl)',
        margin: theme.spacing(0, 0, 1, 0),
        wordBreak: 'break-word',
        ...(sender ? {
            backgroundColor: 'var(--joy-palette-primary-softBg)',
            marginLeft: 'auto',
            textAlign: 'right',
            borderBottomRightRadius: 0,
        } : {
            backgroundColor: 'var(--joy-palette-neutral-softBg)',
            marginRight: 'auto',
            textAlign: 'left',
            borderBottomLeftRadius: 0,
        }),
    });

    return (
        <Box sx={messageStyle}>
            <Typography level="body2" component="span">
                <div dangerouslySetInnerHTML={{ __html: output }} />
            </Typography>
        </Box>
    );
}

ChatBoxMessage.propTypes = {
    message: PropTypes.instanceOf(ClassroomMessage).isRequired,
    sender: PropTypes.bool.isRequired,
};

export default ChatBoxMessage;
