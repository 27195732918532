import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import classroomMessageActions from "actions/classroomMessageActions";
import ChatBox from "components/core/ChatBox";
import {Classroom, ClassroomMessage, Profile} from 'domain';

const ClassroomChatBox = ({ classroom, user }) => {
    const dispatch = useDispatch();
    const messages = useSelector(({classroomMessage}) => classroomMessage.list);
    const isLoading = useSelector(({classroomMessage}) => classroomMessage.isListLoading);

    const updateMessages = (newMsg) => {
        dispatch(classroomMessageActions.set(newMsg))
    }

    const handleSend = (text) => {
        dispatch(classroomMessageActions.save(new ClassroomMessage({
            sent: new Date(),
            status: ClassroomMessage.STATUS_UNREAD,
            sender: user.get('user'),
            classroom,
            text
        })))
    }

    useEffect(() => {
        if(classroom && classroom.id) {
            dispatch(classroomMessageActions.list(classroom)).then(() => {
                dispatch(classroomMessageActions.watch(classroom, {
                    'update': updateMessages,
                    'create': updateMessages,
                    'enter': updateMessages,
                }))
            })
        }

        return () => {
            if (classroom && classroom.id) {
                dispatch(classroomMessageActions.unwatch(classroom))
            }
        }
    }, [classroom])

    return (
        <ChatBox messages={messages} user={user} loading={isLoading} onSubmit={handleSend} />
    );
}

ClassroomChatBox.propTypes = {
    classroom: PropTypes.instanceOf(Classroom),
    user: PropTypes.instanceOf(Profile)
};

ClassroomChatBox.defaultProps = {
    classroom: null,
    user: null
};
export default ClassroomChatBox;
