import {ClassroomMessage} from 'domain';
import WatcherUtils from "utils/WatcherUtils";

const Parse = global.Parse;

const CLASSROOM_MESSAGE_FIELDS = Object.keys(ClassroomMessage.DEFAULTS);

const list = (classroom) => ({
    type: 'LIST_CLASSROOM_MESSAGE',
    payload: new Parse.Query(ClassroomMessage)
        .equalTo('classroom', classroom)
        .select(CLASSROOM_MESSAGE_FIELDS)
        .ascending('sent')
        .find(),
});

const delist = () => ({
    type: 'LIST_CLASSROOM_MESSAGE',
    payload: Promise.resolve([]),
});

const watch = (classroom, functions) => {
    return {
        type: 'WATCH_CLASSROOM_MESSAGE',
        meta: { classroom },
        payload: WatcherUtils.watch(
            'classroomMessage',
            classroom.id,
            functions,
            new Parse.Query(ClassroomMessage)
                .equalTo('classroom', classroom)
                .select(CLASSROOM_MESSAGE_FIELDS)
        ),
    };
};


const unwatch = (classroom) => {
    return {
        type: 'UNWATCH_CLASSROOM_MESSAGE',
        meta: { classroom },
        payload: WatcherUtils.unwatch('classroomMessage', classroom.id),
    };
};

const save = (classroomMessage) => {
    return {
        type: 'SAVE_CLASSROOM_MESSAGE',
        meta: { message: classroomMessage },
        payload: classroomMessage.save(),
    };
};

const set = (message) => {
    return {
        type: 'SET_CLASSROOM_MESSAGE',
        meta: { message },
        payload: Promise.resolve(message),
    };
};

const markAsRead = (id) => {
    return {
        type: 'MARK_CLASSROOM_MESSAGE_AS_READ',
        meta: { id },
        payload: Parse.Cloud.run('mark-classroom-message-read', { id }),
    };
};

const clear = () => ({
    type: `CLEAR_CLASSROOM_MESSAGE`,
    meta: {},
    payload: Promise.resolve(null)
});

export default {
    markAsRead,
    list,
    delist,
    watch,
    unwatch,
    save,
    set,
    clear,
};

