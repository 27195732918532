const Parse = global.Parse;

/**
 * Attempts to log in a user.
 *
 * @param {string} username - The username of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise<Object>} - A promise that resolves to the user details.
 */
const login = (username, password) => {
    return {
        type: 'LOGIN_USER',
        payload: Parse.User.logIn(username, password),
    };
};

/**
 * Logs out the current user.
 *
 * @returns {Promise} - A promise that resolves when the user is logged out.
 */
const logout = () => {
    return {
        type: 'LOGOUT_USER',
        payload: Parse.User.logOut(),
    };
};

/**
 * Register a new user.
 *
 * @param {string} email - The email address to register.
 * @param {string} password - The password to register.
 * @returns {Promise<Object>} - A promise that resolves to the new user details.
 */
const signup = (email, password) => {
    // Create a new instance of the Parse.User class
    const user = new Parse.User();

    // Set email as both username and email for the user
    user.set("username", email);
    user.set("email", email);
    user.set("password", password);

    // Use the signUp method to register the user
    return {
        type: 'ADD_USER',
        payload: user.signUp(),
    };
};

const reset = (email) => {
    return {
        type: 'RESET_USER',
        meta: { email },
        payload: Parse.User.requestPasswordReset(email),
    };
};

/**
 * Fetches the current authenticated user's details.
 *
 * @returns {Object|null} - The current user's details or null if not logged in.
 */
const me = () => {
    return {
        type: 'RESTORE_USER',
        payload: Parse.User.current(),
    };
};

export default {
    login,
    logout,
    signup,
    reset,
    me,
};
