const Parse = global.Parse;


const subscribe = () => ({ type: 'SUBSCRIBE', payload: Parse.Cloud.run('subscribe') });

const update = (sessionId) => ({
    type: 'UPDATE_SUBSCRIBE_STATUS',
    payload: Parse.Cloud.run('update-subscription-status', { sessionId })
});

const createBillingPortalSession = () => ({
    type: 'CREATE_BILLING_PORTAL_SESSION',
    payload: Parse.Cloud.run('create-billing-portal-session')
});

export default { subscribe, update, createBillingPortalSession };


