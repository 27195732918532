import React, { useState, useEffect } from 'react';
import {AspectRatio, Stack, Avatar, Typography} from "@mui/joy";
import {useSelector} from "react-redux";

import CommonUtils from "utils/CommonUtils";
import ClassroomChatBox from "components/classroom/ClassroomChatBox";
import {Profile} from 'domain';

const ClassroomBar = () => {
    const classroom = useSelector(({ classroom }) => classroom.current);
    const me = useSelector(({ profile }) => profile.me);
    const [otherProfile, setOtherProfile] = useState(null)

    useEffect(() => {
        if (classroom && me) {
            if (me.type === Profile.TYPE_STUDENT) {
                setOtherProfile(classroom.tutor?.get('profile'))
            } else {
                setOtherProfile(classroom.student?.get('profile'))
            }
        }
    }, [me, classroom])

    return (
        <Stack
            gap={2}
            sx={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                top: 0,
                p: 2,
                width: 'var(--ofall-controls-width)',
                backgroundColor: 'var(--joy-palette-neutral-softBg)',
                zIndex: 'var(--joy-zIndex-popup)',
            }}>
            <AspectRatio ratio="16/9">
                <Stack gap={1}>
                    <Avatar variant="solid" color="primary" size="lg" src={otherProfile?.picUrl}
                        sx={{width: 86, height: 86}}
                    >
                        {CommonUtils.getInitials(otherProfile?.name)}
                    </Avatar>
                    <Typography level="h4" color="neutral">{otherProfile?.name}</Typography>
                </Stack>
            </AspectRatio>
            <ClassroomChatBox classroom={classroom} user={me} />
        </Stack>
    );
}

ClassroomBar.propTypes = {};

export default ClassroomBar;
