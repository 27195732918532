import React from 'react';
import {Skeleton, Stack} from "@mui/joy";

const ChatBoxSkeleton = () => (
    <Stack gap={2}>
        <Skeleton width="60%" height={52} variant="rectangular" sx={{marginLeft: "auto"}}/>
        <Skeleton width="60%" height={52} variant="rectangular"/>
        <Skeleton width="60%" height={52} variant="rectangular" sx={{marginLeft: "auto"}}/>
        <Skeleton width="60%" height={52} variant="rectangular"/>
    </Stack>
);

export default ChatBoxSkeleton;
