import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from '@mui/joy/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    Typography,
    Sheet,
    IconButton,
    List,
    ListItem,
    ListItemContent,
    GlobalStyles
} from '@mui/joy';

import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';

import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuIcon from '@mui/icons-material/MenuOpen';
import CourseIcon from "@mui/icons-material/Class";
import ClassroomIcon from "@mui/icons-material/School";

import authActions from "actions/authActions";
import SubscriptionNotice from "components/profile/SubscriptionNotice";

import logo from "../../img/ofall-logo.png";

export default function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, setIsOpen] = React.useState(!isSmScreen);
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const me = useSelector(({ profile }) => profile.me);

    const isPathActive = (path) => {
        return location.pathname.startsWith(path);
    };

    const go = (path) => () => {
        navigate(path);
    };

    const handleLogout = () => {
        dispatch(authActions.logout());
    };

    useEffect(() => {
        setIsOpen(!isSmScreen);
    }, [isSmScreen]);

    if(!me?.id || !isAuthorized) {
        return null;
    }

    return (
        <>
            <IconButton
                onClick={() => setIsOpen(!isOpen)}
                variant={isOpen ? 'soft' : 'solid'}
                size={isOpen ? 'sm' : 'lg'}
                color='neutral'
                sx={{
                    position: 'fixed',
                    top: 16,
                    left: isOpen ? 'calc(var(--Sidebar-width) - 48px)' : -24,
                    transition: 'all 0.6s',
                    zIndex: 1300,
                    '&:hover': {
                        left: isOpen ? 'calc(var(--Sidebar-width) - 48px)' : -6,
                    },
                    '& .MuiSvgIcon-root': {
                        transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                        transition: 'transform 0.8s',
                    }
                }}
            >
                <MenuIcon />
            </IconButton>
            {isOpen && isSmScreen && (
                <Box
                    sx={{
                        position: 'fixed',
                        zIndex: 'var(--joy-zIndex-popup)',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'var(--joy-palette-background-backdrop)',
                        transition: 'opacity 0.6s',
                        opacity: { xs: isOpen ? 1 : 0, md: 0 },
                    }}
                    onClick={() => setIsOpen(false)}
                />
            )}
            <Sheet
                className="Sidebar"
                sx={{
                    position: 'sticky',
                    transform: isOpen ? 'translateX(0)' : 'translateX(-95%)',
                    marginLeft: { xs: 'calc(8px - var(--Sidebar-width))', md: isOpen ? 0 : 'calc(12px - var(--Sidebar-width))' },
                    overflow: 'hidden',
                    left: 0,
                    width: 'var(--Sidebar-width)',
                    p: 2,
                    transition: 'all 0.6s',
                    zIndex: 'var(--joy-zIndex-popup)',
                    height: '100dvh',
                    top: 0,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <GlobalStyles
                    styles={(theme) => ({
                        ':root': {
                            '--Sidebar-width': '220px',
                            [theme.breakpoints.up('lg')]: {
                                '--Sidebar-width': '240px',
                            },
                        },
                    })}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1.5,
                        alignItems: 'center',
                    }}
                >
                    <img src={logo} alt="Logo" height={36} />
                    <Typography
                        color="primary"
                        level="h3"
                        noWrap
                        variant="plain"
                    >
                        OFALL
                    </Typography>
                </Box>
                <Divider />
                <Box
                    sx={{
                        minHeight: 0,
                        overflow: 'hidden auto',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        [`& .${listItemButtonClasses.root}`]: {
                            gap: 1.5,
                        },
                        minWidth: { xs: 160 },
                    }}
                >
                    <List
                        size="sm"
                        sx={{
                            gap: 1,
                            '--List-nestedInsetStart': '30px',
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        }}
                    >
                        <ListItem>
                            <ListItemButton onClick={go('/classroom')}
                                selected={location.pathname === '/' || isPathActive('/classroom')}
                            >
                                <ClassroomIcon />
                                <ListItemContent>
                                    <Typography level="title-sm">Classroom</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton onClick={go('/course')}
                                selected={isPathActive('/course')}
                            >
                                <CourseIcon />
                                <ListItemContent>
                                    <Typography level="title-sm">Course Catalog</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>


                        <ListItem>
                            <ListItemButton
                                onClick={go('/message')}
                                selected={isPathActive('/message')}
                            >
                                <QuestionAnswerRoundedIcon />
                                <ListItemContent>
                                    <Typography level="title-sm">Messages</Typography>
                                </ListItemContent>
                                <Chip
                                    size="sm"
                                    color={me.cntUnreadMessages > 0 ? 'primary' : 'neutral'}
                                    variant={me.cntUnreadMessages > 0 ? 'solid' : 'soft'}
                                    sx={{'--Chip-paddingInline': '8px'}}
                                >
                                    {me.cntUnreadMessages}
                                </Chip>
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <List
                        size="sm"
                        sx={{
                            mt: 'auto',
                            flexGrow: 0,
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                            '--List-gap': '8px',
                            mb: 2,
                        }}
                    >
                        <SubscriptionNotice small />
                        <ListItem>
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                href="https://ofall.org"
                                target="_blank"
                            >
                                <SupportRoundedIcon />
                            Support
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton onClick={go(`/profile/${me.id}`)}>
                                <SettingsRoundedIcon />
                            Settings
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Avatar
                        variant="outlined"
                        size="sm"
                        src={me.picUrl}
                    />
                    <Box sx={{ minWidth: 0, flex: 1, overflow: 'hidden' }}>
                        <Typography level="title-sm" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {me.name}
                        </Typography>
                        <Typography level="body-xs" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {me.email}
                        </Typography>
                    </Box>
                    <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
                        <LogoutRoundedIcon />
                    </IconButton>
                </Box>
            </Sheet>
        </>
    );
}
