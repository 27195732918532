import {Course} from 'domain';
const Parse = global.Parse;

const COURSE_FIELDS = ['name', 'tagline', 'description', 'language', 'status', 'tutor']

const get = (id) => ({
    type: 'GET_COURSE',
    meta: { id },
    payload: new Parse.Query(Course)
        .select(COURSE_FIELDS)
        .include(['tutor', 'tutor.profile'])
        .get(id),
});

const set = (course) => ({
    type: 'SET_COURSE',
    meta: { course },
    payload: Promise.resolve(course),
});

const save = (course) => ({
    type: 'SAVE_COURSE',
    meta: { course },
    payload: course.save(),

});

const find = ({ text = null, language = null, status = null }) => {
    const query = new Parse.Query(Course);

    // Select the fields
    query.select(COURSE_FIELDS);
    query.include(['tutor', 'tutor.profile']);

    // Text filter
    if (text) {
        const textQuery = new Parse.Query(Course);
        textQuery.matches('name', text, 'i');
        const descriptionQuery = new Parse.Query(Course);
        descriptionQuery.matches('description', text, 'i');
        query._orQuery([textQuery, descriptionQuery]);
    }

    // Language filter
    if (language) {
        query.equalTo('language', language);
    }

    // Status filter
    if (Array.isArray(status) && status.length > 0) {
        query.containedIn('status', status);
    } else if (status === null) {
        query.notEqualTo('status', 'INACTIVE');
    }

    return {
        type: 'FIND_COURSE',
        meta: { text, language, status },
        payload: query.find(),
    };
};

const findByTutor = (tutor, status = Course.STATUS_ACTIVE) => ({
    type: 'FIND_COURSE',
    meta: { tutor },
    payload: new Parse.Query(Course)
        .equalTo('status', status)
        .equalTo('tutor', tutor)
        .select(COURSE_FIELDS)
        .descending('name')
        .find(),
});

const clear = () => ({
    type: 'CLEAR_COURSE',
    payload: Promise.resolve(null)
});

export default {
    get,
    set,
    save,
    find,
    findByTutor,
    clear,
};

