import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import ProcessingOverlay from "components/core/ProcessingOverlay";
import authActions from "actions/authActions";
import profileActions from "actions/profileActions";
import courseActions from "actions/courseActions";
import messageActions from "actions/messageActions";
import sectionActions from "actions/sectionActions";
import materialActions from "actions/materialActions";
import classroomActions from "actions/classroomActions";
import {Profile} from "domain";

const Loader = () => {
    const dispatch = useDispatch();
    const { profileId, courseId, messageId, sectionId, materialId, classroomId } = useParams();
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const user = useSelector(({ auth }) => auth.me);
    const profile = useSelector(({ profile }) => profile.me);

    const isAuthorizing = useSelector(({ auth }) => auth.isAuthorizing);
    const isProfileLoading = useSelector(({ profile }) => profile.isLoading);
    const isCourseLoading = useSelector(({ course }) => course.isListLoading);
    const isMessagesLoading = useSelector(({ message }) => message.isListLoading);


    const isLoading = isAuthorizing || isProfileLoading || isCourseLoading || isMessagesLoading;

    const clear = () => {
        dispatch(profileActions.clear())
        dispatch(profileActions.unwatchMe())
    }

    const updateProfile = (newProfile) => {
        dispatch(profileActions.set(newProfile, true))
    }

    /*** Manage Authorization & Profile ***/
    useEffect( () => {
        if (isAuthorized) {
            dispatch(profileActions.me(user))
            dispatch(profileActions.watchMe(user, {
                'update': updateProfile,
                'create': updateProfile,
                'enter': updateProfile,
            }))
        } else {
            clear()
        }

        return () => {
            clear()
        }
    }, [isAuthorized]);

    useEffect(() => {
        dispatch(authActions.me());
    }, []);

    useEffect(() => {
        if (profile) {
            if (profile?.status !== Profile.STATUS_ACTIVE) {
                dispatch(authActions.logout());
            }
        }

        if (profileId) {
            if (profile && profile.id === profileId) {
                dispatch(profileActions.set(profile))
            } else {
                dispatch(profileActions.get(profileId))
            }
        } else {
            dispatch(profileActions.set(null))
        }
    }, [profileId, profile]);

    /*** Load Course ***/
    useEffect(() => {
        if (courseId) {
            dispatch(courseActions.get(courseId)).then(({ value: course }) => {
                dispatch(sectionActions.list(course))
            })
        } else {
            dispatch(courseActions.set(null))
            dispatch(sectionActions.clear())
        }
    }, [courseId]);

    /*** Load Classroom ***/
    useEffect(() => {
        if (classroomId) {
            dispatch(classroomActions.get(classroomId))
        } else {
            dispatch(classroomActions.set(null))
        }
    }, [classroomId]);

    /*** Load Materials ***/
    useEffect(() => {
        if (sectionId) {
            dispatch(sectionActions.get(sectionId)).then(({ value: section }) => {
                dispatch(materialActions.list(section))
            })
        } else {
            dispatch(sectionActions.set(null))
            dispatch(materialActions.clear())
        }
    }, [sectionId]);

    useEffect(() => {
        if (materialId) {
            dispatch(materialActions.get(materialId))
        } else {
            dispatch(materialActions.set(null))
        }
    }, [materialId]);

    /* Load Messages */
    useEffect(() => {
        if (messageId) {
            dispatch(messageActions.get(messageId))
        } else {
            dispatch(messageActions.set(null))
        }
    }, [messageId]);

    return <ProcessingOverlay enabled={isLoading} />;
};

export default Loader;
