import React from 'react';
import PropTypes from 'prop-types';
import { Container, Stack } from '@mui/joy';

import SplashImage from "components/core/SplashImage";

const OverlayModal = ({ children, maxWidth }) => (
    <Container component="main" maxWidth={maxWidth}>
        <SplashImage key='bg-splash-image' />
        <Stack alignItems="center" p={2} mt={2} sx={{backgroundColor: 'background.surface', borderRadius: 'md', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.800'}}>
            {children}
        </Stack>
    </Container>
);

OverlayModal.propTypes = {
    children: PropTypes.node,
    maxWidth: PropTypes.string,
};

OverlayModal.defaultProps = {
    children: null,
    maxWidth: 'xs',
};

export default OverlayModal;
