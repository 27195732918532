import React, { useState, useRef, useEffect } from 'react';
import {Box, IconButton, Sheet, Textarea} from "@mui/joy";
import SendIcon from "@mui/icons-material/Send";
import ChatBoxSkeleton from "components/core/ChatBoxSkeleton";
import PropTypes from "prop-types";
import {ClassroomMessageArray, Profile} from "domain";
import ChatBoxMessage from "components/core/ChatBoxMessage";

const ChatBox = ({ messages, user, onSubmit, loading }) => {
    const [message, setMessage] = useState('');
    const chatThreadRef = useRef(null);

    const handleSubmit = () => {
        if (message.trim()) {
            onSubmit(message);
            setMessage('');
        }
    }

    const handleChange = (event) => {
        setMessage(event.target.value);
    }

    useEffect(() => {
        if (chatThreadRef.current) {
            chatThreadRef.current.scrollTop = chatThreadRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <>
            <Sheet
                ref={chatThreadRef}
                id="chat-thread"
                sx={{
                    position: 'relative',
                    flexGrow: 1,
                    backgroundColor: 'var(--joy-palette-background-body)',
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    justifyContent: 'unset',
                    overflowY: 'auto',
                    overflowX: 'clip',
                }}
            >
                <Box id="thread-content">
                    {loading && <ChatBoxSkeleton />}
                    {messages.map(msg => (
                        <ChatBoxMessage key={msg.id} message={msg} sender={user?.get('user').id === msg.sender.id} />
                    ))}
                </Box>
            </Sheet>
            <Box>
                <Textarea
                    minRows={1}
                    placeholder="Send a message..."
                    value={message}
                    onChange={handleChange}
                    disabled={loading}
                    endDecorator={
                        <IconButton
                            variant="solid"
                            color="primary"
                            size="sm"
                            sx={{ml: 'auto'}}
                            disabled={loading || !message.trim()}
                            onClick={handleSubmit}
                        >
                            <SendIcon />
                        </IconButton>
                    }
                />
            </Box>
        </>
    );
}

ChatBox.propTypes = {
    messages: PropTypes.instanceOf(ClassroomMessageArray).isRequired,
    onSubmit: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Profile),
    loading: PropTypes.bool
};

ChatBox.defaultProps = {
    loading: false,
    user: null,
};

export default ChatBox;
