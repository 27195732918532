import {Message} from 'domain';
const Parse = global.Parse;

const MESSAGE_FIELDS = ['subject', 'body', 'to', 'from', 'group', 'classRoom', 'sent', 'status']

const get = (id) => ({
    type: 'GET_MESSAGE',
    meta: { id },
    payload: new Parse.Query(Message)
        .select(MESSAGE_FIELDS)
        .include(['from', 'from.profile', 'to', 'to.profile', 'group'])
        .get(id),
});

const set = (message) => {
    return {
        type: 'SET_MESSAGE',
        meta: { message },
        payload: Promise.resolve(message),
    };
};

const list = () => ({
    type: 'LIST_MESSAGE',
    payload: new Parse.Query(Message)
        .select(MESSAGE_FIELDS)
        .include(['from', 'from.profile', 'to', 'to.profile', 'group'])
        .descending('sent')
        .find(),
});

const delist = () => ({
    type: 'LIST_MESSAGE',
    payload: Promise.resolve([]),
});

const save = (message) => {
    return {
        type: 'SAVE_MESSAGE',
        meta: { message },
        payload: message.save(),
    };
};

const markAsRead = (messageId) => {
    return {
        type: 'MARK_MESSAGE_AS_READ',
        meta: { messageId },
        payload: Parse.Cloud.run('mark-message-read', { messageId }),
    };
};

const clear = () => ({
    type: `CLEAR_MESSAGE`,
    meta: {},
    payload: Promise.resolve(null)
});

export default {
    get,
    set,
    markAsRead,
    list,
    delist,
    save,
    clear,
};

