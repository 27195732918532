import React from 'react';

const SplashImage = () => (
    <img src={'/img/bg.jpeg'} style={{
        position: 'absolute',
        zIndex: -1,
        width: '100vw',
        height: '100vh',
        top: 0,
        border: 0,
        right: 0,
        left: 0,
        objectFit: 'cover',
        objectPosition: 'center',
    }} />
);

SplashImage.propTypes = {};

export default SplashImage;
