import React, { useRef, useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import {Button, Box, Stack, Select, Option} from '@mui/joy';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CodeIcon from '@mui/icons-material/Code';
import CodeBlockIcon from '@mui/icons-material/Terminal';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import DividerIcon from '@mui/icons-material/HorizontalRule';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

import './RichTextarea.css';

const Quill = ReactQuill.Quill;
const BlockEmbed = Quill.import('blots/block/embed');

class DividerBlot extends BlockEmbed {}
DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'hr';
Quill.register(DividerBlot);

const RichTextarea = ({ value, onChange }) => {
    const reactQuillRef = useRef(null);

    const [editor, setEditor] = useState(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    
    const handleChange = (content) => {
        onChange(content);
    };

    const applyFormat = (format, value = null) => {
        const quillEditor = reactQuillRef.current.getEditor();
        if (format === 'clean') {
            const range = quillEditor.getSelection();
            if (range) {
                quillEditor.removeFormat(range.index, range.length);
            }
        } else if (format === 'divider') {
            const range = quillEditor.getSelection();
            if (range) {
                quillEditor.insertEmbed(range.index, 'divider', true);
                quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
            }
        } else {
            quillEditor.format(format, value || !quillEditor.getFormat()[format]);
        }
    };

    useEffect(() => {
        if (reactQuillRef.current) {
            const quillEditor = reactQuillRef.current.getEditor();
            setEditor(quillEditor);
    
            quillEditor.on('text-change', () => {
                const { redo, undo } = quillEditor.history.stack;
                setCanUndo(undo.length > 0);
                setCanRedo(redo.length > 0);
            });
        }
    }, []);


    const handleUndo = () => {
        if (editor && canUndo) {
            editor.history.undo();
        }
    };
    
    const handleRedo = () => {
        if (editor && canRedo) {
            editor.history.redo();
        }
    };


    const handleSelectChange = (format) => (event) => {
        if (event && event.target && event.target.value !== undefined) {
            applyFormat(format, event.target.value);
        }
    };

    return (
        <>
            <Stack id="toolbar" flexDirection="row" gap={0.5} alignItems="center">
                <Button onClick={() => applyFormat('bold')} variant="plain" color="neutral">
                    <FormatBoldIcon />
                </Button>
                <Button onClick={() => applyFormat('italic')} variant="plain" color="neutral"><FormatItalicIcon /></Button>
                <span className="ql-formats">
                    <select className="ql-color"></select>
                </span>
                <span className="ql-formats">
                    <select className="ql-background"></select>
                </span>
                <Button onClick={() => applyFormat('underline')} variant="plain" color="neutral"><FormatUnderlinedIcon /></Button>
                <Button onClick={() => applyFormat('strike')} variant="plain" color="neutral"><FormatStrikethroughIcon /></Button>
                <Box>
                    <Select
                        defaultValue=""
                        onChange={handleSelectChange('header')}
                        size="small"
                        sx={{ minWidth: '100px' }}
                    >
                        <Option value="">Normal</Option>
                        <Option value="1">Header 1</Option>
                        <Option value="2">Header 2</Option>
                        <Option value="3">Header 3</Option>
                        <Option value="4">Header 4</Option>
                    </Select>
                </Box>
                <Button onClick={() => applyFormat('list', 'ordered')} variant="plain" color="neutral"><FormatListNumberedIcon /></Button>
                <Button onClick={() => applyFormat('list', 'bullet')} variant="plain" color="neutral"><FormatListBulletedIcon /></Button>
                <Button onClick={() => applyFormat('code-block')} variant="plain" color="neutral"><CodeBlockIcon /></Button>
                <Button onClick={() => applyFormat('code')} variant="plain" color="neutral"><CodeIcon /></Button>
                <Button onClick={() => applyFormat('divider')} variant="plain" color="neutral"><DividerIcon /></Button>
                <Button onClick={() => applyFormat('blockquote')} variant="plain" color="neutral"><FormatQuoteIcon /></Button>
                <Button onClick={handleUndo} variant="plain" color="neutral" disabled={!canUndo}><UndoIcon /></Button>
                <Button onClick={handleRedo} variant="plain" color="neutral" disabled={!canRedo}><RedoIcon /></Button>
                <Button onClick={() => applyFormat('clean')} variant="plain" color="neutral"><FormatClearIcon /></Button>
            </Stack>

            <ReactQuill
                ref={reactQuillRef}
                value={value}
                onChange={handleChange}
                modules={{ 
                    toolbar: { container: '#toolbar' },
                    history: { delay: 1000,  maxStack: 100 }
                }}
            />
        </>
    );
};

RichTextarea.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default RichTextarea;
