import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LoginForm from "components/core/LoginForm";
import ProfileFormInterrupt from "components/profile/ProfileFormInterrupt";
import ProcessingOverlay from "components/core/ProcessingOverlay";

const Authorize = () => {
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const isProfileLoading = useSelector(({ profile }) => profile.isLoading);
    const isAuthLoading = useSelector(({ auth }) => auth.isLoading);
    const myProfile = useSelector(({ profile }) => profile.me);

    if (isProfileLoading || isAuthLoading) {
        return <ProcessingOverlay enabled />
    }

    if (!isAuthorized) {
        return <LoginForm />;
    }

    if (!myProfile?.id) {
        return <ProfileFormInterrupt />;
    }

    return <Outlet />;
};

export default Authorize;
