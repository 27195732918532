import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Divider, Avatar, Typography, Input, Button, Link, FormControl, FormLabel } from '@mui/joy';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import authActions from 'actions/authActions';
import Notices from "components/core/Notices";
import OverlayModal from "components/core/OverlayModal";

const Copyright = () => {
    return (
        <Typography level="body-xs" color="neutral" align="center" mt={2}>
            {'Copyright © '}
            <Link color="neutral" href="https://ofall.org/" target="_blank">
                OFALL LLC
            </Link>{' '}
            {new Date().getFullYear()}
            {'. All Rights Reserved.'}
        </Typography>
    );
}

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(authActions.login(username, password));
    };

    return (
        <OverlayModal>
            <Notices key="notices" />
            <Avatar sx={{mt: 2}} color="primary">
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                    Sign in
            </Typography>
            <Stack component="form" width="100%" onSubmit={handleSubmit} noValidate spacing={2} mb={2} alignItems="stretch">
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                        placeholder="Email Address"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                        type="password"
                        placeholder="Your Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>

                <Button
                    type="submit"
                    fullWidth
                    variant="solid"
                    disabled={username.length === 0 || password.length === 0}
                >
                        Sign In
                </Button>

            </Stack>

            <Divider />

            <Stack direction="row" justifyContent="space-between" mt={2} gap={2}>
                <Button component={RouterLink} to="/signup" color="primary" variant="plain">
                        Sign Up
                </Button>
                <Button component={RouterLink} to="/forgot-password" color="primary" variant="plain">
                        Forgot Password?
                </Button>
            </Stack>

            <Copyright />
        </OverlayModal>
    )
};

LoginForm.propTypes = {};

export default LoginForm;
