import {Material} from 'domain';
const Parse = global.Parse;

const MATERIAL_FIELDS = ['name', 'type', 'status', 'data', 'attachment', 'size', 'section.id'];

const get = (id) => ({
    type: 'GET_MATERIAL',
    meta: { id },
    payload: new Parse.Query(Material)
        .select(MATERIAL_FIELDS)
        .get(id),
});

const set = (material) => {
    return {
        type: 'SET_MATERIAL',
        meta: { material },
        payload: Promise.resolve(material),
    };
};

const save = (material) => {
    return {
        type: 'SAVE_MATERIAL',
        meta: { material },
        payload: material.save(),
    };
};

const list = (section) => ({
    type: 'LIST_MATERIAL',
    meta: { section },
    payload: new Parse.Query(Material)
        .equalTo('status', Material.STATUS_ACTIVE)
        .equalTo('section', section)
        .select(MATERIAL_FIELDS)
        .find(),
});

const clear = () => ({
    type: 'CLEAR_MATERIAL',
    payload: Promise.resolve(null)
});

export default {
    get,
    set,
    save,
    list,
    clear,
};
