import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/joy';

import ClassroomListItem from './ClassroomListItem';
import {ClassroomArray, Profile} from 'domain';

const ClassroomList = ({ classrooms, perspective, onClick }) => {
    return (
        <Box display="flex" flexWrap="wrap" gap={2}>
            {classrooms.map((classroom) => (
                <ClassroomListItem
                    key={classroom.id}
                    classroom={classroom}
                    perspective={perspective}
                    onClick={onClick}
                />
            ))}
        </Box>
    );
};

ClassroomList.propTypes = {
    classrooms: PropTypes.instanceOf(ClassroomArray).isRequired,
    perspective: PropTypes.oneOf(Profile.TYPES).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ClassroomList;
