import {Classroom} from 'domain';
const Parse = global.Parse;

const CLASSROOM_FIELDS = Object.keys(Classroom.DEFAULTS);

const get = (id) => ({
    type: 'GET_CLASSROOM',
    meta: { id },
    payload: new Parse.Query(Classroom)
        .select(CLASSROOM_FIELDS)
        .include(['tutor', 'tutor.profile','student', 'student.profile','course'])
        .get(id),
});

const set = (classroom) => {
    return {
        type: 'SET_CLASSROOM',
        meta: { classroom },
        payload: Promise.resolve(classroom),
    };
};

const save = (classroom) => {
    return {
        type: 'SAVE_CLASSROOM',
        meta: { classroom },
        payload: classroom.save(),
    };
};

const findByStudent = (student) => {
    return {
        type: 'FIND_CLASSROOM',
        meta: { student },
        payload: new Parse.Query(Classroom)
            .equalTo('student', student)
            .select(CLASSROOM_FIELDS)
            .include(['tutor', 'tutor.profile','course'])
            .descending('updatedAt')
            .find(),
    };
};

const findByTutor = (tutor) => {
    return {
        type: 'FIND_CLASSROOM',
        meta: { tutor },
        payload: new Parse.Query(Classroom)
            .equalTo('tutor', tutor)
            .select(CLASSROOM_FIELDS)
            .include(['student', 'student.profile','course'])
            .descending('updatedAt')
            .find(),
    };
};

const findByCourse = (course) => {
    return {
        type: 'FIND_CLASSROOM',
        meta: { course },
        payload: new Parse.Query(Classroom)
            .equalTo('course', course)
            .select(CLASSROOM_FIELDS)
            .include(['student', 'student.profile','course'])
            .descending('updatedAt')
            .find(),
    };
};

const clear = () => ({
    type: 'CLEAR_CLASSROOM',
    payload: Promise.resolve(null)
});

export default {
    get,
    set,
    save,
    findByStudent,
    findByTutor,
    findByCourse,
    clear,
};

