import React from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import {Avatar, Chip} from "@mui/joy";

import {Profile} from "domain";
import CommonUtils from "utils/CommonUtils";

const ProfileChip = ({ profile, disabled }) => {
    const navigate = useNavigate();
    return (
        <Chip
            variant="soft"
            onClick={disabled ? null : () => { navigate(`/profile/${profile.id}`) }}
            sx={{
                "--Chip-decoratorChildHeight": "32px",
                "--Chip-minHeight": "42px"
            }}
            startDecorator={
                <Avatar src={profile.picUrl} variant="solid">
                    {CommonUtils.getInitials(profile.name)}
                </Avatar>
            }
        >
            {profile.name}
        </Chip>
    );
}

ProfileChip.propTypes = {
    profile: PropTypes.instanceOf(Profile),
    disabled: PropTypes.bool,
};

ProfileChip.defaultProps = {
    disabled: false,
}

export default ProfileChip;
