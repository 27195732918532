import React from 'react';
import {Outlet} from "react-router-dom";
import { Box } from "@mui/joy";
import ClassroomBar from "components/classroom/ClassroomBar";

const ClassroomLayout = () => (
    <Box
        id="classroom-layout"
        sx={{
            '--ofall-controls-width': {xs: '180px', sm: '240px', md: '320px'},
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            height: '100%',
        }}
    >
        <Box
            id="classroom-content"
            flexGrow="1"
            sx={{
                marginRight: 'calc(var(--ofall-controls-width))'
            }}>
            <Outlet />
        </Box>
        <ClassroomBar />
    </Box>

);

ClassroomLayout.propTypes = {};

export default ClassroomLayout;
