import {Section} from 'domain';
const Parse = global.Parse;

const SECTION_FIELDS = ['name', 'summary', 'description', 'course.id']

const get = (id) => ({
    type: 'GET_SECTION',
    meta: { id },
    payload: new Parse.Query(Section)
        .select(SECTION_FIELDS)
        .get(id),
});

const set = (section) => {
    return {
        type: 'SET_SECTION',
        meta: { section },
        payload: Promise.resolve(section),
    };
};

const save = (section) => {
    return {
        type: 'SAVE_SECTION',
        meta: { section },
        payload: section.save(),
    };
};

const list = (course) => ({
    type: 'LIST_SECTION',
    meta: { course },
    payload: new Parse.Query(Section)
        .select(SECTION_FIELDS)
        .equalTo('course', course)
        .find(),
});

const clear = () => ({
    type: 'CLEAR_SECTION',
    payload: Promise.resolve(null)
});

export default {
    get,
    set,
    save,
    list,
    clear,
};

