import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Input } from '@mui/joy';

const ForgotPasswordForm = ({ onReset, onChange, email }) => {
    return (
        <Stack component="form" onSubmit={onReset} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <Input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={onChange}
            />
        </Stack>
    );
};

ForgotPasswordForm.propTypes = {
    email: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
