import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Box} from "@mui/joy";
import CourseIcon from "@mui/icons-material/Language";

import {Course} from "domain";
import SignLanguageIcon from "@mui/icons-material/SignLanguage";

const LanguageLabel = ({ language }) => (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar
            variant="soft"
            size="sm"
            color="neutral"
            src={language.toLowerCase() !== 'asl' ? `https://unpkg.com/language-icons/icons/${language.toLowerCase()}.svg` : null}
        >
            {language.toLowerCase() !== 'asl' ? <CourseIcon/> : <SignLanguageIcon />}
        </Avatar>
        <Box sx={{display: {xs: 'none', sm: 'inherit'}}}>
            {Course.LANGUAGE_LABELS[language] ?? language}
        </Box>
    </Box>
);

LanguageLabel.propTypes = {
    language: PropTypes.oneOf(Course.LANGUAGES)
};

export default LanguageLabel;
