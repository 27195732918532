import React from 'react';
import { MenuItem } from '@mui/joy';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useColorScheme } from '@mui/joy/styles';

const ColorSchemeMenuItem = () => {
    const { mode, setMode } = useColorScheme();
    const toggleColorScheme = () => {
        setMode(mode === 'light' ? 'dark' : 'light');
    };

    return (
        <MenuItem onClick={toggleColorScheme}>
            {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
            {mode === 'light' ? 'Dark Mode' : 'Light Mode'}
        </MenuItem>
    );
};

export default ColorSchemeMenuItem;
