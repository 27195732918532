import React, { useState } from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import { Stack, Avatar, Typography, Input, Button, Link, FormControl, FormLabel, Checkbox } from '@mui/joy';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';

import authActions from "actions/authActions";
import appActions from "actions/appActions";
import OverlayModal from "components/core/OverlayModal";
import { Notice } from 'domain';

const SignUpForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(authActions.signup(email, password)).then(() => {
            dispatch(appActions.addNotice(new Notice({
                title: 'Registration Successful',
                details: 'Please check your email. Click the validation link to activate your account.',
                type: Notice.TYPE_SUCCESS
            }))).then(() => {
                navigate('/classroom');
            });
        })
    };

    return (
        <OverlayModal>
            <Avatar sx={{mt: 2}} color="primary">
                <PersonAddOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                    Sign Up
            </Typography>
            <Stack component="form" width="100%" onSubmit={handleSubmit} noValidate spacing={2} alignItems="stretch">
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Confirm Password</FormLabel>
                    <Input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </FormControl>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox
                        checked={acceptTerms}
                        onChange={(e) => setAcceptTerms(e.target.checked)}
                    />
                    <Typography level="body-sm">
                            I accept the <Link href="https://www.ofall.org/privacy-policy/" target="_blank">Privacy Policy</Link> and <Link href="https://www.ofall.org/terms-and-conditions/" target="_blank">Terms & Conditions</Link>
                    </Typography>
                </Stack>

                <Button
                    type="submit"
                    fullWidth
                    variant="solid"
                    disabled={!acceptTerms || !email || !password || password !== confirmPassword}
                >
                        Sign Up
                </Button>

            </Stack>
        </OverlayModal>
    );
};

export default SignUpForm;
